<template>
  <div>
    <van-loading type="spinner" v-if="loading" />
    <van-nav-bar
      :title="messageTitle"
      left-text=""
      left-arrow
      :fixed="true"
      @click-left="back"
    />
    <div id="content">
      <div class="title">{{ title }}</div>
      <p class="time">{{ createTime }}</p>
      <div class="content" v-html="content"></div>
    </div>
  </div>
</template>
<script>
import { noticeRead } from "@network/api";
export default {
  name: "",
  watch: {
    $route: "getPath",
  },
  data() {
    return {
      loading: false,
      id: null,
      messageTitle: "",
      title: "",
      content: "",
      createTime: "",
    };
  },
  mounted() {
    this.getPath();
    this.id = this.$route.params.messageId;
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log(from);
  //   next((vm) => {
  //     //因为当钩子执行前，组件实例还没被创建
  //     // vm 就是当前组件的实例相当于上面的 this，所以在 next 方法里你就可以把 vm 当 this 来用了。
  //     console.log(vm); //当前组件的实例
  //     if (from.name == "message") {
  //       vm.getPath(); //表示提交订单页面
  //     } else if (from.name == null) {
  //       return;
  //     }
  //   });
  // },
  methods: {
    getPath() {
      this.id = this.$route.params.messageId;
      this.title = this.$route.params.title;
      this.content = this.$route.params.content;
      this.createTime = this.$route.params.createTime;
      if (this.$route.path == "/messageDetail") {
        this.messageTitle = this.$t("message.detail");
        this.loading = true;
        noticeRead({ id: this.id })
          .then((res) => {
            this.loading = false;
            if(res.code === '10011'){
            return
          }
            if (res.code == "000000") {
            } else {
              this.$toast(res.message);
            }
          })
          .catch((err) => {
        this.loading = false;
            this.$toast(err.message);
          });
      }
    },
    back() {
      this.$router.back(-1);
    },
  },
};
</script>
<style scoped>
#content {
  padding: 0 24px 24px 24px;
  height: calc(100vh - 60px);
  box-sizing: border-box;
}
.van-nav-bar__content {
  height: 60px;
}
.van-nav-bar__title {
  font-weight: bold;
}
.title {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #333;
  margin: 20px 0;
}
.time {
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  color: #999;
  margin: 14px 0;
}
.content {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  margin: 33px 0;
  color: #333;
}
</style>
